import { getConfig } from "./config";
import { FLAG } from "@/constants";
import local from "./localStorage";
import { uniqueId } from "@/utils/strUtils";
import { SCENE_INIT_URL } from "@/config/global";


const FETCH_OPTS = {
    method: 'GET',
    headers:{
      'token':getStorage('token')
    }
}

/**
 * 请求单个场景/素材/文件夹
 * @param  {Number} id   场景/素材ID
 * @param  {String} flag 场景类型：'downloadFile'/'templateScene'/'userResource'/'officialResource'/'favoriteScene'/'favoriteResource'/
 * @return {Object} sceneData 场景描述文件数据
 */
export async function loadFile(id, flag){
  if(!id){
    console.error('id不存在！');
    return null;
  }
  let url = getConfig(flag, 'get').replace('{id}', id);
  // console.log('请求单个场景/素材',url)
  let fetchOpts = Object.assign({},FETCH_OPTS);
  // url = '../modelRemove copy.json';
  const uid = uniqueId();
  url = `${SCENE_INIT_URL}?r=${uid}`;

  const json = await fetch(url,fetchOpts).then(response => response.json())

  if(json.error){
    console.error(`获取数据失败：${json.code} - ${json.error}`);
    return null;
  }
  addToken(json.data)
  return json.data;
}

/**
 * 上传场景(除描述文件外的其他信息)
 * @param  {Object} options  上传参数：id场景id、name场景名称、tags场景标签、thumbnail场景缩略图
 * @param  {String} flag     类型：'userScene'/'userResource'
 * @return {Object} res 上传结果
 */
export function uploadScene(options = {}){
  if(Object.keys(options).length === 0){
    console.error('参数为空！');
    return null;
  }
  const{
    id,
    name, 
    tags, 
    thumbnail, 
  } = options

  let url = getConfig(FLAG.USER_SCENE, 'post');
  // console.log('上传场景',url)

  const formData = new FormData();
  formData.append('id', id);
  formData.append('name', name);
  formData.append('tags', tags);
  formData.append('thumbnail', thumbnail);
  let fetchOpts = Object.assign({},FETCH_OPTS);
  fetchOpts.method = 'POST';
  fetchOpts.body = formData;
  return fetch(url, fetchOpts)
  .then(response => response.json())
  .then(res => {
    if(res.code == 200){
      console.log('上传成功');
      return res;
    }else{
      console.error(`上传失败：${res.code} - ${res.error}`);
      return null
    }
  })
}

/**
 * 单独上传场景描述文件
 * @param  {String} id   场景id
 * @param  {JSON} file 场景描述文件
 * @return {Object} res 上传结果
 */
export function uploadSceneFile(id, file){
  if(!id){
    console.error('id不存在！');
    return null;
  }

  const fileData = new FormData();
  fileData.append('id', id);
  fileData.append('file', file);
  let url = getConfig(FLAG.UPLOAD_FILE, 'uploadSceneFile').replace('{id}', id);
  let fetchOpts = Object.assign({},FETCH_OPTS);
  fetchOpts.method = 'POST';
  fetchOpts.body = fileData;

  return fetch(url, fetchOpts)
  .then(response => response.json())
  .then(res => {
    if(res.code == 200){
      console.log('上传成功');
      return res;
    }else{
      console.error(`上传失败：${res.code} - ${res.error}`);
      return null
    }
  })
}

/**
 * 上传素材
 * @param  {Object} options  上传参数：id素材id(*required)、name素材名称(*required)、tags素材标签、thumbnail素材缩略图(*required)、file素材文件/file_url素材链接、
 * format模型类别(FBX OBJ GLTF)/服务类型(3DTiles WMTS WMS)(*required)、folder_id对应文件夹id(*required)、menu_id菜单类别、type素材类别（模型or服务）(*required)
 * @return {Object} res 上传结果
 */
export function uploadResource(options = {}){
  if(Object.keys(options).length === 0){
    console.error('参数为空！');
    return null;
  }
  let url = getConfig(FLAG.USER_MATERIAL, 'post');
  console.log('上传素材',url)
  const formData = new FormData();
  for(let key in options){
    formData.append(`${key}`, options[key])
  }
  let fetchOpts = Object.assign({},FETCH_OPTS);
  fetchOpts.method = 'POST';
  fetchOpts.body = formData;
  return fetch(url, fetchOpts)
  .then(response => response.json())
  .then(res => {
    if(res.code == 200){
      console.log('上传成功');
      return res;
    }else{
      console.error(`上传失败：${res.code} - ${res.error}`);
      return null
    }
  })
}

/**
 * 用户新建文件夹
 * @param  {Object} options id文件夹id、name文件夹名称
 * @return {Object} res 新建结果
 */
export function uploaFolder(options = {}){
  if(!options.id || !options.name){
    console.error('参数为空！');
    return null;
  }
  let url = getConfig(FLAG.USER_FOLDER, 'post');
  console.log('新建文件夹',url)
  const formData = new FormData();
  for(let key in options){
    formData.append(`${key}`, options[key])
  }
  let fetchOpts = Object.assign({},FETCH_OPTS);
  fetchOpts.method = 'POST';
  fetchOpts.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  fetchOpts.body = formData;
  return fetch(url, fetchOpts)
  .then(response => response.json())
  .then(res => {
    if(res.code == 200){
      console.log('新建文件夹成功');
      return res;
    }else{
      console.error(`新建文件夹失败：${res.code} - ${res.error}`);
      return null
    }
  })
}

/**
 * 删除用户场景/素材/文件夹
 * @param  {Array} idArr   场景id/素材id/文件夹id数组
 * @param  {String} flag 类型：'userScene'/'userResource'/'userFolder'
 * @return {Object} res 待定
 */
export function deleteData(idArr = [], flag = FLAG.USER_SCENE){
  let url = getConfig(flag, 'delete');

  console.log('删除用户场景/素材/文件夹', url)
  let fetchOpts = Object.assign({},FETCH_OPTS);
  fetchOpts.method = 'DELETE';
  fetchOpts.headers['Content-Type'] = 'application/json';
  fetchOpts.body = JSON.stringify({ids:idArr});
  return fetch(url, fetchOpts)
  .then(response => response.json())
  .then(res => {
    if(res.code == 200){
      console.log('删除成功');
      return res;
    }else{
      console.error(`删除失败：${res.code} - ${res.error}`);
      return null
    }
  })
}

/**
 * 更新用户场景/素材/文件夹
 * @param  {Object} options  更新参数：场景id/素材id、name场景/素材/文件夹名称、tags场景/素材标签、thumbnail场景/素材缩略图、
 * @param  {String} flag     类型：'userScene'/'userResource'/'userFolder'
 * @return {Object} res 待定
 */
export function updateData(options = {}, flag = FLAG.USER_SCENE){
  if(Object.keys(options).length === 0){
    console.error('参数为空！');
    return null;
  }

  let url = getConfig(flag, 'put');
  console.log('更新用户场景/素材/文件夹',url)
  const formData = new FormData();
  for(let key in options){
    formData.append(`${key}`, options[key])
  }

  let fetchOpts = Object.assign({},FETCH_OPTS);
  fetchOpts.method = 'PUT';
  fetchOpts.body = formData;
  return fetch(url, fetchOpts)
  .then(response => response.json())
  .then(res => {
    if(res.code !== 200){
      console.error(`更新失败：${res.code} - ${res.error}`);
      return null;
    }
    console.log('更新成功');
    return res.data;
  })
}

/**
 * 更新场景描述文件
 * @param  {String} id  场景id
 * @param  {JSON} file  场景描述文件
 * @return {Object} res 更新结果
 */
export function updateSceneFile(id, file){
  if(!id){
    console.error('id不存在！');
    return null;
  }
  let url = getConfig(FLAG.UPLOAD_FILE, 'uploadSceneFile').replace('{id}', id);
  const formData = new FormData();
  formData.append('file', file);
  let fetchOpts = Object.assign({},FETCH_OPTS);
  fetchOpts.method = 'PUT';
  fetchOpts.body = formData;
  return fetch(url, fetchOpts)
  .then(response => response.json())
  .then(res => {
    if(res.code !== 200){
      console.error(`更新文件失败：${res.code} - ${res.msg}`);
      return null;
    }
    console.log('更新文件成功');
    return res.data;
  })
}

/**
 * 列表排序/搜索
 * @param  {Object} options  排序/搜索参数：page页码、size每页场景个数、sort排序方式、asc内部排序依据：升序or降序(true or false)、search关键词(名称/标签)、menu_id类别、folder_id文件夹id
 * @param  {String} flag     类型：'userScene'/'templateScene'/'userResource'/'officialResource'/'favoriteScene'/'favoriteResource'/'userFolder'
 * @return {Object} list 待定
 */
export function loadList(options = '', flag = FLAG.USER_SCENE){ // loadlist
  const {
    page, 
    size, 
    sort, 
    asc, 
    search,
    menu_id,
    folder_id,
  } = options
  if(!page || !size){
    console.error('page/size不能为空！');
    return null;
  }
  let url = getConfig(flag, 'list').replace('{page}', page).replace('{size}', size)
  sort ? url = `${url}&sort=${sort}` : url;
  asc ? url = `${url}&asc=${asc}` : url;
  search ? url = `${url}&search=${search}` : url;
  menu_id ? url = `${url}&menu_id=${menu_id}` : url;
  folder_id ? url = `${url}&folder_id=${folder_id}` : url;


  console.log('场景排序/搜索', url)
  let fetchOpts = Object.assign({},FETCH_OPTS);

  return fetch(url, fetchOpts)
  .then(response => response.json())
  .then(res => {
    if(res.error){
      console.error(`获取数据失败：${res.code} - ${res.error}`);
      return null;
    }
    console.log('列表请求成功');
    res.data.list.forEach((obj)=>{
      addToken(obj)
    })

    return res.data;
  })
}


/**
 * 请求编辑器左侧菜单栏内容
 * @return {Array} data 包含对象的数组
 */
export async function loadMenu(){
  let url = getConfig(FLAG.OFFICIAL_MATERIAL, 'getMenuTree');
  url = '../menu.json';
  // console.log('加载菜单栏', url)
  // console.log(fetchOpts)
  let fetchOpts = Object.assign({},FETCH_OPTS);
  const json = await fetch(url, fetchOpts)
  .then(response => response.json())

  if (json.error) {
    console.error(`获取数据失败：${json.code} - ${json.msg}`);
    return null;
  }
  return json.data;
}


export async function login(){
  let url = getConfig('', 'login')
  
  await fetch(url, {
    method: 'POST',
    headers:{
      'Content-Type': 'application/json'
    },
    body:JSON.stringify({
      "username": "test"
      }),
  })
  .then(response => response.json())
  .then(res => {
    if(res.token){
      console.log('登陆成功',res);
      setStorage('token',res.token)
      return res;
    }else{
      console.error(`登陆失败：${res.message}`);
      return null;
    }
  })
}

// 由于html标签无法设置请求头，这里给返回的url添加token(临时方案)
function addToken(obj){
  
  let {thumbnail_url, file_url} = obj;
  if(thumbnail_url) obj.thumbnail_url = `${thumbnail_url}?token=${getStorage('token')}`
  if(file_url) obj.file_url = `${file_url}?token=${getStorage('token')}`
   
}

export function getStorage(cacheId) {
  if (local.support()) {
    let cache =  local.get(cacheId)
    if (!cache) return null;
    return cache
  }
}

export function setStorage(cacheId, storage) {
  if (local.support()) {
    local.set(cacheId, storage);
  }
}