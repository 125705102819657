// 封装localStorage

const isSupportLS = typeof(Storage) && localStorage.setItem && localStorage.getItem && localStorage.removeItem && localStorage.clear;

const local = {
  set(key, value) {
    if (!key || !value) {
          return null
      }
    key = key.toString();
    window.localStorage.setItem(key, JSON.stringify(value));
  },
  get(key) {
    if (!key) {
        return null
    }
    key = key.toString();
    const data = window.localStorage.getItem(key);
    return JSON.parse(data);
 },
  support: () => isSupportLS,
  deleteKey(key) {
    if (!key) {
      return;
    }
    key = key.toString();
    window.localStorage.removeItem(key);
  },
  clear() {
    window.localStorage.clear();
  }
};
export default local;
