//常量
export const FLAG = {
    USER_SCENE: 'userScene', // 我的场景
    TEMPLATE_SCENE: 'templateScene', // 模板场景
    USER_MATERIAL: 'userResource', // 我的素材
    OFFICIAL_MATERIAL: 'officialResource', // 官方素材
    FAVORITE_SCENE: 'favoriteScene', // 我的收藏-场景
    FAVORITE_MATERIAL: 'favoriteResource', // 我的收藏-素材
    UPLOAD_FILE:'uploadFile', // 上传场景描述文件
    DOWNLOAD_FILE:'downloadFile', // 下载场景描述文件
    USER_FOLDER: 'userFolder'// 用户文件夹
}