<template>
  <t-layout key="side">
    <t-aside><layout-side-nav /></t-aside>
    <t-layout>
      <t-content><layout-content /></t-content>
    </t-layout>
  </t-layout>
</template>

<script setup>
import { watch } from 'vue';
import { useRoute } from 'vue-router';

import LayoutContent from './components/LayoutContent.vue';
import LayoutSideNav from './components/LayoutSideNav.vue';

import { prefix } from '../config/global';

import '../style/layout.less';

const route = useRoute();


watch(
  () => route.path,
  () => {
    document.querySelector(`.${prefix}-layout`).scrollTo({ top: 0, behavior: 'smooth' });
  },
);
</script>

<style lang="less" scoped></style>
