<template>
  <div>
    <template v-for="item in list" :key="item.path">
      <t-menu-item :name="item.path" :value="item.path" :to="item.path">
        <template #icon>
          <component :is="item.icon" class="t-icon" />
        </template>
        {{ item.title }}
      </t-menu-item>
    </template>
  </div>
</template>
<script setup>
import { computed } from "vue";

const props = defineProps({
  navData: {
    type: Array,
    default: () => [],
  },
});

const list = computed(() => {
  const { navData } = props;
  return navData;
});
</script>
