import { defineStore } from 'pinia';

const state = {
  editing: false,
  editingModel: null,
  previewInfo: {
    id: '模型001',
    type: 'model',
    url: 'https://fe-1304214004.file.myqcloud.com/images/ssp/20221215/td7gs6tx.png',
  },
  showPreviewCard: false,
  modelAttributes: {
    id: 0,
    name: 0,
    position: ['0', '0', '0'],
    rotation: ['0', '0', '0'],
    scale: ['0', '0', '0'],
  },
  activeTool: 0,
  rollbackList: [],
  rollfrontList: [],
  rollbackListLength: 0,
  rollfrontListLength: 0,
  activeIndex: 0,
  menuData: null,
  defaultStyleIds: ['DEFAULT', 'DARK', 'LIGHT', 'GAME', 'FROST_WHITE', 'DEEP_BLACK', 'BRIGHT', 'QUIET'],
  curStyleId: 'DEFAULT',
};

export const editDataStore = defineStore('editData', {
  state: () => state,
})