import { defineStore } from 'pinia';

const state = {
    createFormDialogVisible: false,
    changeFormDialogVisible: false,
    selectedScene: null,
    toolMenuVisible: false,
    menuValue: "base",
};


export const pageStatusStore = defineStore('pageStatus', {
  state: () => state,
})