<template>
  <l-side-nav
    :layout="settingStore.layout"
    :is-fixed="settingStore.isSidebarFixed"
    :menu="sideMenu"
    :is-compact="settingStore.isSidebarCompact"
  />
</template>

<script setup>
import LSideNav from "./SideNav.vue";
import { dashboardRouter } from "@/router";

const settingStore = {
  isSidebarCompact: false,
  layout: "side",
  isSidebarFixed: true,
};
const sideMenu = dashboardRouter.children.map((item) => {
  return {
    path: dashboardRouter.path + "/" + item.path,
    title: item.meta.title,
    icon: item.meta.icon,
  };
});
</script>
