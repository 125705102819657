<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </transition>
  </router-view>
</template>

<script setup>

</script>
<style lang="less" scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
