import { createRouter, createWebHistory } from 'vue-router';
import { DashboardIcon, AppIcon, StarIcon, ControlPlatformIcon, UsergroupIcon } from 'tdesign-icons-vue-next';
import { ref } from 'vue';
import Layout from '@/layouts/index.vue';
import { login } from '@/api/sceneDataLoader/socket';

export const dashboardRouter =   {
  path: '/dashboard',
  component: Layout,
  redirect: '/dashboard/scene',
  name: 'dashboard',
  meta: {
    title: '首页',
    icon: DashboardIcon,
    orderNo: 0,
  },
  beforeEnter: () => {
    login()
  },
  children: [
    {
      path: 'scene',
      name: 'DashboardScene',
      component: () => import('@/pages/dashboard/scene/index.vue'),
      meta: {
        title: '我的场景',
        icon: AppIcon,
      },
    },
    {
      path: 'material',
      name: 'DashboardMaterial',
      component: () => import('@/pages/dashboard/material/index.vue'),
      meta: {
        title: '我的素材',
        icon: ControlPlatformIcon,
      },
    },
    {
      path: 'share',
      name: 'DashboardShare',
      component: () => import('@/pages/dashboard/share/index.vue'),
      meta: {
        title: '共享社区',
        icon: UsergroupIcon,
      },
    },
    {
      path: 'favorite',
      name: 'DashboardFavorite',
      component: () => import('@/pages/dashboard/favorite/index.vue'),
      meta: {
        title: '我的收藏',
        icon: StarIcon,
      },
    },
  ],
}

// 其他固定路由
const defaultRouterList = [
  {
    path: '/',
    redirect: '/scene/hdroad',
  },
  // dashboardRouter,
  {
    path: '/scene',
    redirect: '/scene/hdroad',
  },
  {
    path: "/scene/:id",
    name: "scene",
    component: () => import("../pages/SceneEdit/SceneView.vue"),
  },
  {
    path: "/scene/:id/preview",
    component: () => import("../pages/SceneEdit/ScenePreview.vue"),
  },
];


export const allRoutes = [...defaultRouterList];

const router = createRouter({
  history: createWebHistory('/'),
  routes: allRoutes,
});
export const dashboardTabRoute = ref('');
router.afterEach((to, from) => {
  if(to.path.startsWith('/dashboard/')){
    dashboardTabRoute.value = to.path;
  }
})
export default router;
